export default async function SUBSCRIBE(data) {
	try {
		const response = window.BX.ajax.runComponentAction(
			window.BX.message('SUBSCRIBE_COMPONENT_NAME_' + window.footerSubscribeFormId),
			'trySubscribe',
			{
				mode: 'class',
				data: data,
			}
		);
		return response;
	} catch (error) {
		return {
			status: 'error',
			errors: error,
		};
	}
}
