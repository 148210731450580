import { classInstance, enableScroll, disableScroll, isBxPanel } from '@/shared/helpers/helpers';

export class Header {
	constructor(selector, options = {}) {
		this.$container = selector;

		if (!this.$container) return;

		this.scroll = window.scrollY;
		this.open = false;

		this.selectorMenu = options.selectorMenu || '.js-header__menu';
		this.selectorToggle = options.selectorToggle || '.js-header__toggle';
		this.selectorLocation = options.selectorLocation || '.js-header__location';
		this.selectorLocationOpen = options.selectorLocationOpen || '.js-header__location-open';
		this.selectorLocationClose = options.selectorLocationClose || '.js-header__location-close';

		this.init();
	}

	init() {
		classInstance.set(this.$container, { header: this });
		this.$menu = this.$container.querySelector(this.selectorMenu);
		this.$toggle = this.$container.querySelector(this.selectorToggle);
		this.$location = this.$container.querySelector(this.selectorLocation);
		this.$locationOpen = this.$container.querySelector(this.selectorLocationOpen);
		this.$locationClose = this.$container.querySelector(this.selectorLocationClose);

		this.open = this.$menu.classList.contains('open');
		this.$toggle.classList.toggle('open', this.open);

		this.$toggle.addEventListener('click', this.clickHandler.bind(this));
		this.$locationOpen.addEventListener('click', this.locationOpenHandler.bind(this));
		this.$locationClose.addEventListener('click', this.locationCloseHandler.bind(this));

		if (!isBxPanel()) {
			this.$container.classList.add('header--sticky');
			window.addEventListener('scroll', this.scrollHandler.bind(this));
		}

		// search
		this.$headerSearch = this.$container.querySelector('.js-header-search');
		this.$searchResult = this.$container.querySelector('.js-header-search-result');
		this.$searchInput = this.$container.querySelector('.js-header-search-input');
		this.$clearBtn = this.$container.querySelector('.js-header-search-clear');
		this.$closeSearchBtn = this.$container.querySelector('.js-header-search-close');
		this.$showSearchBtn = document.querySelector('.js-header-search-show');

		const searchElements = [
			this.$headerSearch,
			this.$searchInput,
			this.$clearBtn,
			this.$closeSearchBtn,
			this.$showSearchBtn,
			this.$searchResult,
		];

		if (searchElements.every((el) => el !== null)) {
			this.$searchInput.value.length > 0 && this.$clearBtn.classList.remove('hidden');

			this.$searchInput.addEventListener('input', this.inputHandler);
			this.$searchInput.addEventListener('focus', this.focusHandler);
			this.$showSearchBtn.addEventListener('click', this.showSearch);
			this.$clearBtn.addEventListener('click', this.clearHandler);
			this.$closeSearchBtn.addEventListener('click', this.closeSearch);

			const mq768 = window.matchMedia('(max-width: 768px)');

			const handleMQ = (e) => (e.matches ? this.lockBody() : this.unlockBody());

			mq768.addEventListener('change', handleMQ);
		}
	}

	destroy() {
		classInstance.del(this.$container, 'header');
		this.$toggle.removeEventListener('click', this.clickHandler);
		this.$locationOpen.removeEventListener('click', this.locationOpenHandler);
		this.$locationClose.removeEventListener('click', this.locationCloseHandler);
		window.removeEventListener('scroll', this.scrollHandler);
	}

	reinit() {
		this.destroy();
		this.init();
	}

	clickHandler() {
		this.toggleMenu(!this.open);
	}

	scrollHandler() {
		if (document.body.classList.contains('disable-scroll')) return;
		if (window.scrollY != this.scroll) {
			this.toggleScrolled(window.scrollY > 400);
			this.toggleCollapsed(window.scrollY >= this.scroll && window.scrollY > 600);
		}
		this.scroll = window.scrollY;
	}

	locationOpenHandler() {
		this.toggleLocation(true);
	}

	locationCloseHandler() {
		this.toggleLocation(false);
	}

	toggleMenu(key) {
		this.open = key;
		this.$menu.classList.toggle('open', key);
		this.$toggle.classList.toggle('open', key);
		if (key) {
			disableScroll();
		} else {
			enableScroll();
			this.toggleLocation(key);
		}
	}

	toggleLocation(key) {
		this.$location.classList.toggle('open', key);
		if (key) {
			disableScroll();
			this.$container.dispatchEvent(
				new CustomEvent('locationOpen', {
					detail: {
						header: this,
					},
				}),
			);
		} else {
			enableScroll();
			this.$container.dispatchEvent(
				new CustomEvent('locationСlose', {
					detail: {
						header: this,
					},
				}),
			);
		}
	}

	toggleScrolled(key) {
		document.body.classList.toggle('header-scrolled', key);
	}

	toggleCollapsed(key) {
		document.body.classList.toggle('header-collapsed', key);
	}

	inputHandler = (e) => {
		e.target.value.length > 0 ? this.$clearBtn.classList.remove('hidden') : this.$clearBtn.classList.add('hidden');
		if (e.target.value.length > 2) {
			this.showSearch();
		} else if (this.$headerSearch.classList.contains('search-visible') && window.innerWidth >= 768) {
			this.closeSearch();
		}
	};

	focusHandler = (e) => {
		if (e.target.value.length > 2) this.showSearch();
	};

	clearHandler = () => {
		this.$searchInput.value = '';
		this.$clearBtn.classList.add('hidden');
	};

	showSearch = () => {
		if (!this.$headerSearch.classList.contains('search-visible')) {
			this.$headerSearch.classList.add('search-visible');
			window.addEventListener('click', this.closeHeaderSearchByWindow);

			if (window.innerWidth < 768) {
				document.body.classList.add('search-is-open');
			}
		}
	};

	closeSearch = () => {
		this.$headerSearch.classList.remove('search-visible');
		document.body.classList.remove('search-is-open');
		window.removeEventListener('click', this.closeHeaderSearchByWindow);
	};

	closeHeaderSearchByWindow = (e) => {
		this.$headerSearch && !this.$headerSearch.contains(e.target) && !e.target.closest('.js-header-search-show') && this.closeSearch();
	};

	lockBody = () => {
		if (this.$headerSearch.classList.contains('search-visible')) {
			document.body.classList.add('search-is-open');
		}
		this.$headerSearch.style.transition = 'none';
		setTimeout(() => {
			this.$headerSearch.style.transition = '';
		}, 1000);
	};

	unlockBody = () => {
		document.body.classList.remove('search-is-open');

		this.$searchResult.style.transition = 'none';
		setTimeout(() => {
			this.$searchResult.style.transition = '';
		}, 1000);
	};
}
