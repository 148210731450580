import { classInstance } from '@/shared/helpers/helpers';
import { Form } from '@/shared/form/form';

import { CALLBACK } from '@/api/request/index';

export class OrderCall {
	constructor(selector) {
		this.$container = selector;

		if (!this.$container) return;

		this.init();
	}

	init() {
		classInstance.set(this.$container, { orderCall: this });
		this.$submit = this.$container.querySelector('button[type="submit"]');

		this.instance = new Form(this.$container);
		this.resultInstance = classInstance.get(document.querySelector('.js-success-result')).successResult;
		this.$container.addEventListener('submit', this.submitHandler.bind(this));
	}

	destroy() {
		classInstance.del(this.$container, 'orderCall');
		this.$container.removeEventListener('submit', this.submitHandler);
	}

	reinit() {
		this.destroy();
		this.init();
	}

	submitHandler(event) {
		event.preventDefault();

		if (!this.instance.formCheck(false)) return;

		this.$submit.classList.add('loading');

		CALLBACK(new FormData(this.$container)).then((response) => {
			this.$submit.classList.remove('loading');
			if (response.status === 'success') {
				this.resultInstance.open({
					title: response.message?.title || 'Ваша заявка принята',
					text: response.message?.text || 'Наши менеджеры свяжутся с вами <br/>в ближайшее время',
				});
			}
			if (response.status === 'error') {
				app.notify.append({
					type: response.status,
					delay: '10000',
					content: response.errors,
				});
			}
		});
	}
}
