import { classInstance } from '@/shared/helpers/helpers';

import Swiper from 'swiper';
import { FreeMode, Navigation, EffectFade, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

export class SwiperThumbs {
	constructor(selector, options = {}) {
		this.$container = selector;

		if (!this.$container) return;

		this.selectorMain = options.selectorMain || '.js-swiper-thumbs__main';
		this.selectorPreview = options.selectorPreview || '.js-swiper-thumbs__preview';
		this.selectorNext = options.selectorNext || '.js-swiper-prev';
		this.selectorPrev = options.selectorPrev || '.js-swiper-next';

		this.init();
	}

	init() {
		classInstance.set(this.$container, { swiperThumbs: this });

		this.swiperPreview = new Swiper(this.selectorPreview, {
			modules: [FreeMode, EffectFade],
			slidesPerView: 'auto',
			freeMode: true,
			rewind: true,
			watchSlidesProgress: true,
			spaceBetween: 2,
		});

		this.swiperMain = new Swiper(this.selectorMain, {
			modules: [Navigation, Thumbs, EffectFade],
			slidesPerView: 1,
			spaceBetween: 8,
			effect: 'fade',
			rewind: true,
			fadeEffect: {
				crossFade: true,
			},
			navigation: {
				prevEl: this.$container.querySelector('.js-swiper-prev'),
				nextEl: this.$container.querySelector('.js-swiper-next'),
			},
			thumbs: {
				swiper: this.swiperPreview,
			},
		});
	}

	destroy() {
		classInstance.del(this.$container, 'swiperThumbs');
		this.swiperMain.destroy(true, true);
		this.swiperPreview.destroy(true, true);
	}

	update() {
		this.destroy();
		this.init();
	}

	setSlide(slide) {
		this.swiperMain.slideTo(slide);
	}
}
