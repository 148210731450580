const vars = {
	breakpoint: {
		mobile: 425,
		tablet: 640,
		tabletMiddle: 768,
		laptop: 1024,
		desktopSmall: 1280,
		desktopMiddle: 1480,
		desktopLarge: 1680,
	},
};

export { vars };
