import Swiper from 'swiper';
import 'swiper/css';

export class AddedToCartSwiper {
	constructor(selector) {
		this.$container = selector;
		if (!this.$container) return;
		this.init();
	}

	init() {
		this.$slides = this.$container.querySelectorAll('.js-added-to-card-slide');

		if (this.$slides.length > 1) {
			new Swiper(this.$container, {
				slidesPerView: 'auto',
				spaceBetween: 16,
				freeMode: true,
				freeModeSticky: true,
				slidesOffsetBefore: 16,
				slidesOffsetAfter: 16,
				on: {
					lock: function () {
						this.slideReset(500, false);
					},
				},

				breakpoints: {
					640: {
						slidesPerView: 3,
						spaceBetween: 16,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 39,
						slidesOffsetBefore: 0,
						slidesOffsetAfter: 0,
					},
				},
			});
		}
	}
}
