import.meta.globEager('@/assets/images/**/*.{jpg,jpeg,png}', {
	query: { format: 'webp', quality: 80 },
});

import { vars } from './config/variables';

import { disableScroll, enableScroll, getDeviceType, getLocation, classInstance } from '@/shared/helpers/helpers';

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import { PictureLazy } from '@/shared/common/picture/picture';
import { Collapse } from '@/shared/common/collapse/collapse';
import { Dropdown } from '@/shared/common/dropdown/dropdown';
import { Popover } from '@/shared/common/popover/popover';
import { Scrollbar } from '@/shared/common/scrollbar/scrollbar';
import { Scrollto } from '@/shared/common/scrollto/scrollto';
import { Sticky } from '@/shared/common/sticky/sticky';
import { Tabs } from '@/shared/common/tabs/tabs';
import { Timer } from '@/shared/common/timer/timer';

import { Form } from '@/shared/form/form';
import { Modal } from '@/shared/modal/modal';
import { Notify } from '@/shared/notify/notify';

import { CatalogCard } from '@/features/catalog/catalog-card/catalog-card';
import { AddedToCart } from '@/features/modal/added-to-cart/added-to-cart';
import { AddedToCartSwiper } from '@/features/modal/added-to-cart/added-to-cart-swiper';
import { ModalCartPreview } from '@/features/modal/cart-preview/cart-preview';
import { SuccessResult } from '@/entities/success-result/success-result';
import { OrderBuyClick } from '@/features/common/order-buy-click/order-buy-click';
import { OrderBuyClickOne } from '@/features/common/order-buy-click/order-buy-click-one';
import { OrderCall } from '@/features/common/order-call/order-call';
import { OrderContact } from '@/features/common/order-contact/order-contact';
import { OrderService } from '@/features/common/order-service/order-service';
import { OrderConsultation } from '@/features/common/order-consultation/order-consultation';
import { PersonalLogin } from '@/features/personal/personal-login/personal-login';
import { FooterSubscribe } from '@/widgets/footer/footer-subscribe/footer-subscribe';

window.app = {};
window.vars = vars;

window.app.classInstance = classInstance;
window.app.disableScroll = disableScroll;
window.app.enableScroll = enableScroll;
window.app.getDeviceType = getDeviceType;
window.app.getLocation = getLocation;

window.app.Fancybox = Fancybox;
window.app.PictureLazy = PictureLazy;
window.app.Collapse = Collapse;
window.app.Dropdown = Dropdown;
window.app.Popover = Popover;
window.app.Scrollbar = Scrollbar;
window.app.Scrollto = Scrollto;
window.app.Sticky = Sticky;
window.app.Tabs = Tabs;
window.app.Timer = Timer;

window.app.Form = Form;
window.app.Notify = Notify;
window.app.Modal = Modal;

window.app.AddedToCart = AddedToCart;
window.app.AddedToCartSwiper = AddedToCartSwiper;
window.app.CatalogCard = CatalogCard;
window.app.SuccessResult = SuccessResult;
window.app.OrderBuyClick = OrderBuyClick;
window.app.OrderBuyClickOne = OrderBuyClickOne;
window.app.OrderCall = OrderCall;
window.app.OrderContact = OrderContact;
window.app.OrderService = OrderService;
window.app.OrderConsultation = OrderConsultation;
window.app.PersonalLogin = PersonalLogin;
window.app.FooterSubscribe = FooterSubscribe;

import '@/layouts/layout';

window.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('picture.js-lazy').forEach((element) => new PictureLazy(element));
	document.querySelectorAll('.js-collapse').forEach((element) => new Collapse(element));
	document.querySelectorAll('.js-dropdown').forEach((element) => new Dropdown(element));
	document.querySelectorAll('.js-popover').forEach((element) => new Popover(element));
	document.querySelectorAll('.js-scrollbar').forEach((element) => new Scrollbar(element));
	document.querySelectorAll('.js-scrollto').forEach((element) => new Scrollto(element));
	document.querySelectorAll('.js-tabs').forEach((element) => new Tabs(element));
	document.querySelectorAll('.js-form').forEach((element) => new Form(element));

	new Notify();
	new Modal();

	Fancybox.bind('[data-fancybox]');

	document.querySelectorAll('.js-catalog-card').forEach((element) => new CatalogCard(element));
	document.querySelectorAll('.js-success-result').forEach((element) => new SuccessResult(element));
	document.querySelectorAll('.js-order-buy-click').forEach((element) => new OrderBuyClick(element));
	document.querySelectorAll('.js-order-buy-click-one').forEach((element) => new OrderBuyClickOne(element));
	document.querySelectorAll('.js-order-call').forEach((element) => new OrderCall(element));
	document.querySelectorAll('.js-order-contact').forEach((element) => new OrderContact(element));
	document.querySelectorAll('.js-order-service').forEach((element) => new OrderService(element));
	document.querySelectorAll('.js-order-consultation').forEach((element) => new OrderConsultation(element));
	document.querySelectorAll('.js-personal-login').forEach((element) => new PersonalLogin(element));
	document.querySelectorAll('.js-footer-subscribe').forEach((element) => new FooterSubscribe(element));

	new AddedToCart(document.querySelector('.js-modal-added-to-cart'));
	new AddedToCartSwiper(document.querySelector('.js-added-to-cart-slider'));
	new ModalCartPreview(document.querySelector('.js-modal-cart-preview'));
});
