export function useGetValid($element: HTMLInputElement): ResultVE {
	const result: ResultVE = {
		valid: false,
		error: false,
		message: '',
	};

	const valid = $element.checked;

	result.valid = valid;
	result.error = !valid;

	return result;
}
