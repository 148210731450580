export class AddedToCart {
	constructor(selector) {
		this.$container = selector;
		if (!this.$container) return;
		this.init();
	}

	init() {
		this.$container.style.transition = '';

		this.$buyBtns = this.$container.querySelectorAll('.js-added-to-cart-add');

		this.$closeButtons = this.$container.querySelectorAll('.js-added-to-cart-close');
		this.$clickButton = this.$container.querySelector('.js-added-to-cart-click');

		this.$buyBtns.length > 0 && this.$buyBtns.forEach((btn) => btn.addEventListener('click', this.addToCartHandler.bind(this)));

		this.$closeButtons.length > 0 &&
			this.$closeButtons.forEach((btn) => btn.addEventListener('click', this.closeModalHandler.bind(this)));

		this.$clickButton?.addEventListener('click', this.clickModalHandler.bind(this));
	}

	addToCartHandler(e) {
		const slide = e.target.closest('.js-added-to-card-slide');
		slide.classList.toggle('added');

		const btn = slide.querySelector('.js-added-to-cart-add');
		const btnText = btn.children[1];

		if (slide.classList.contains('added')) {
			btnText.textContent = btn.dataset.added;
		} else {
			btnText.textContent = btn.dataset.nonAdded;
		}
	}

	closeModalHandler() {
		this.$container.classList.remove('active');
		document.body.classList.remove('lock');
	}

	clickModalHandler() {
		this.$container.classList.remove('active');
		document.body.classList.remove('lock');
		app.modal.open('#order-buy-click-one');
	}
}
