export default async function CONTACT(data) {
	try {
		const response = await fetch(`${import.meta.env.VITE_API_URL}local/o2k_api/form/sendForm`, {
			method: 'POST',
			mode: 'same-origin',
			headers: { 'X-Bitrix-Csrf-Token': window.BX ? window.BX.bitrix_sessid() : '' },
			body: data,
		})
			.then((data) => {
				return data.json();
			})
			.then((data) => {
				return data;
			});
		return response;
	} catch (error) {
		return {
			status: 'error',
			errors: error,
		};
	}
}
