import { classInstance } from '@/shared/helpers/helpers';

export class HeaderConfirm {
	constructor(selector, options = {}) {
		this.$container = selector;

		if (!this.$container) return;

		this.selectorAccept = options.selectorAccept || '.js-header-confirm__accept';
		this.selectorReject = options.selectorReject || '.js-header-confirm__reject';

		this.init();
	}

	init() {
		classInstance.set(this.$container, { headerСonfirm: this });
		this.$accept = this.$container.querySelector(this.selectorAccept);
		this.$reject = this.$container.querySelector(this.selectorReject);

		this.$accept?.addEventListener('click', this.acceptHandler.bind(this));
		this.$reject?.addEventListener('click', this.rejectHandler.bind(this));
	}

	destroy() {
		classInstance.del(this.$container, 'headerСonfirm');
		this.$accept?.removeEventListener('click', this.acceptHandler);
		this.$reject?.removeEventListener('click', this.rejectHandler);
	}

	reinit() {
		this.destroy();
		this.init();
	}

	acceptHandler() {
		this.toggle(false);
		this.dispatchAccept();
	}

	rejectHandler() {
		this.toggle(false);
		this.dispatchReject();
	}

	toggle(key) {
		this.$container.classList.toggle('active', key);
	}

	dispatchAccept() {
		this.$container.dispatchEvent(new CustomEvent('accept'));
	}

	dispatchReject() {
		this.$container.dispatchEvent(new CustomEvent('reject'));
	}
}
