import { classInstance } from '@/shared/helpers/helpers';

import { FormObserver } from './composition/observer';
import { validate } from './composition/validate';

import { FormInput } from './form-element/form-input/form-input';
import { FormPassword } from './form-element/form-input/form-password';
import { FormSelect } from './form-element/form-select/form-select';
import { FormComplete } from './form-element/form-complete/form-complete';
import { FormTextArea } from './form-element/form-textarea/form-textarea';
import { FormCheckbox } from './form-element/form-checkbox/form-checkbox';
import { FormRadio } from './form-element/form-radio/form-radio';
import { FormFile } from './form-element/form-file/form-file';
import { FormCode } from './form-element/form-code/form-code';

/**
 * @desc UI Компонент Form
 * @category 3 Form
 * @example
 * document.querySelectorAll('.js-form').forEach((element) => {new Form(element)});
 * @constructor
 * @param {HTMLFormElement} selector - HTMLFormElement
 */

export class Form {
	readonly $form: HTMLFormElement;

	public formValid: boolean;
	protected formObserver: any;
	protected $buttons: Array<HTMLButtonElement>;
	protected $elemens: Array<HTMLElement>;
	protected selectors: string;

	constructor(selector: HTMLFormElement) {
		this.$form = selector;

		if (!this.$form) return;

		this.selectors = '.js-form-element input, .js-form-element textarea, .js-form-element select';

		this.init();
	}

	/**
	 * @desc Инициализировать компонент
	 */
	async init() {
		classInstance.set(this.$form, { form: this });

		this.$buttons = Array.from(this.$form.querySelectorAll('[type="submit"]'));
		this.$elemens = [];

		this.formObserver = new FormObserver(this.$form, this.selectors);
		this.$form.querySelectorAll('.js-form-element input').forEach(($element: HTMLInputElement) => {
			this.$elemens.push($element);
			const type = $element.dataset.type;
			switch (type) {
				case 'form-code':
					new FormCode($element);
					break;

				case 'form-file':
					new FormFile($element);
					break;

				case 'form-checkbox':
					new FormCheckbox($element);
					break;

				case 'form-radio':
					new FormRadio($element);
					break;

				case 'form-complete':
					new FormComplete($element);
					break;

				case 'form-password':
					new FormPassword($element);
					break;

				default:
					new FormInput($element);
					break;
			}
		});
		this.$form.querySelectorAll('.js-form-element select').forEach(($element: HTMLSelectElement) => {
			this.$elemens.push($element);
			new FormSelect($element);
		});
		this.$form.querySelectorAll('.js-form-element textarea').forEach(($element: HTMLTextAreaElement) => {
			this.$elemens.push($element);
			new FormTextArea($element);
		});

		this.checkHandler = this.checkHandler.bind(this);
		this.$form.addEventListener('formChange', this.checkHandler);

		this.resetHandler = this.resetHandler.bind(this);
		this.$form.addEventListener('reset', this.resetHandler);

		this.formCheck(true);
	}

	/**
	 * @desc Удалить обрабочики событий
	 */
	public destroy(): void {
		classInstance.del(this.$form, 'form');
		this.formObserver.destroy();
		this.$form.removeEventListener('formChange', this.checkHandler);
		this.$form.removeEventListener('reset', this.resetHandler);
	}

	/**
	 * @desc Переопределить обрабочики событий
	 * @example
	 * const myForm = app.classInstance.get(document.querySelector('.js-form'));
	 * myForm.form.reinit();
	 */
	public reinit(): void {
		this.destroy();
		this.init();
	}

	/**
	 * @desc Проверить валидность формы
	 * @param {Boolean} [noRender=false] - если указать true - представление не будет обновляться при валидации.
	 * @returns {Boolean} результат валидации
	 * @example
	 * const myForm = app.classInstance.get(document.querySelector('.js-form'));
	 * myForm.form.formCheck();
	 */
	public formCheck(noRender?: boolean): boolean {
		const result: ResultVF = validate(this.$form, this.selectors, noRender);

		this.formValid = result.valid;
		this.render(result.looked);

		return result.valid;
	}

	private checkHandler(event: CustomEvent) {
		this.render(event.detail.looked);
	}

	private resetHandler() {
		this.$elemens.forEach(($element) => {
			$element.dispatchEvent(new CustomEvent('reset'));
		});
	}

	private render(looked: boolean): void {
		this.$buttons.forEach((button) => (button.disabled = looked));
	}
}
