import { messages } from '../../composition/messages';

export function useGetValid($element: HTMLSelectElement, options: OptionsVE): ResultVE {
	const result: ResultVE = {
		valid: true,
		error: false,
		message: '',
	};

	if (!options.noEmpty) {
		result.valid = false;
		result.error = true;
		Array.from($element.selectedOptions).forEach((option) => {
			if (option.value) {
				result.valid = true;
				result.error = false;
				return;
			}
		});
		result.message = messages[options.message]['empty'];
	}

	return result;
}
