import { classInstance } from '@/shared/helpers/helpers';
import { Form } from '@/shared/form/form';
import { Timer } from '@/shared/common/timer/timer';

// import { LOGIN, CODE } from '@/api/request/index';

export class PersonalLogin {
	constructor(selector, options = {}) {
		this.$container = selector;

		if (!this.$container) return;

		this.count = options.count || this.$container.dataset.count;

		this.selectorAuth = options.selectorAuth || '.js-personal-login__auth';
		this.selectorCode = options.selectorCode || '.js-personal-login__code';
		this.selectorPhone = options.selectorPhone || '.js-personal-login__phone';
		this.selectorBack = options.selectorBack || '.js-personal-login__back';
		this.selectorError = options.selectorError || '.js-personal-login__error';
		this.selectorControl = options.selectorControl || '.js-personal-login__control';
		this.selectorTimer = options.selectorTimer || '.js-personal-login__timer';
		this.selectorRepeat = options.selectorRepeat || '.js-personal-login__repeat';

		this.init();
	}

	init() {
		classInstance.set(this.$container, { personalLogin: this });

		this.$authBox = this.$container.querySelector(this.selectorAuth);
		this.$authButton = this.$authBox.querySelector('button[type="submit"]');
		this.$authForm = this.$authBox.querySelector('form');

		this.$codeBox = this.$container.querySelector(this.selectorCode);
		this.$codePhone = this.$codeBox.querySelector(this.selectorPhone);
		this.$codeBack = this.$codeBox.querySelector(this.selectorBack);
		this.$codeError = this.$codeBox.querySelector(this.selectorError);
		this.$codeControl = this.$codeBox.querySelector(this.selectorControl);
		this.$codeTimer = this.$codeBox.querySelector(this.selectorTimer);
		this.$codeRepeat = this.$codeBox.querySelector(this.selectorRepeat);
		this.$codeForm = this.$codeBox.querySelector('form');
		this.$codeEll = this.$codeForm.querySelector('input[required]');

		this.authFormInstance = new Form(this.$authForm);
		this.codeFormInstance = new Form(this.$codeForm);
		this.$authForm.addEventListener('submit', this.submitHandler.bind(this));
		this.$codeEll.addEventListener('elementComplete', this.confirmHandler.bind(this));
		this.$codeBack.addEventListener('click', this.backHandler.bind(this));
		this.$codeRepeat.addEventListener('click', this.repeatHandler.bind(this));
		this.$codeTimer.addEventListener('timerComplete', this.completeHandler.bind(this));

		this.timer = new Timer(this.$codeTimer, {
			count: Number(this.count),
			type: 'number',
			format: (data) => `Отправить повторно, через ${data.seconds.value} ${data.seconds.name}`,
		});
	}

	destroy() {
		classInstance.del(this.$container, 'personalLogin');
		this.$authForm.removeEventListener('submit', this.submitHandler);
		this.$codeEll.removeEventListener('elementComplete', this.confirmHandler);
		this.$codeBack.removeEventListener('click', this.backHandler);
		this.$codeRepeat.removeEventListener('click', this.repeatHandler);
	}

	reinit() {
		this.destroy();
		this.init();
	}

	submitHandler(event) {
		event.preventDefault();

		if (!this.authFormInstance.formCheck(false)) return;

		this.loadPhone(true);

		this.$container.dispatchEvent(
			new CustomEvent('submitPhone', {
				detail: {
					data: new FormData(this.$authForm),
				},
			}),
		);
	}

	confirmHandler() {
		this.loadCode(true);
		this.$container.dispatchEvent(
			new CustomEvent('submitCode', {
				detail: {
					data: new FormData(this.$codeForm),
				},
			}),
		);
	}

	backHandler() {
		this.$codeBox.classList.remove('active');
		this.$authBox.classList.add('active');
	}

	completeHandler() {
		this.$codeControl.classList.remove('active');
	}

	repeatHandler() {
		this.timer.start();

		setTimeout(() => {
			this.$codeControl.classList.add('active');
		}, 500);

		this.$container.dispatchEvent(
			new CustomEvent('submitPhone', {
				detail: {
					data: new FormData(this.$authForm),
				},
			}),
		);
	}

	openCode(phone) {
		this.timer.start();
		this.$codePhone.innerHTML = phone || '';
		setTimeout(() => {
			this.$authBox.classList.remove('active');
			this.$codeBox.classList.add('active');
			this.$codeControl.classList.add('active');
		}, 500);
	}

	setError(key, error) {
		this.$codeBox.classList.toggle('error', key);
		this.$codeError.innerHTML = key ? error : '';
	}

	loadPhone(key) {
		this.$authButton.classList.toggle('loading', key);
	}

	loadCode(key) {
		this.$codeBox.classList.toggle('loading', key);
	}
}
