const createVideo = (element) => {
	if (!element.classList.contains('video--ready')) {
		element.classList.add('video--ready');
		const videoWrapper = element.querySelector('.video2__wrapper');
		const videoPlayer = element.querySelector('.video2__player');
		const videoPlay = element.querySelector('.video2__play');

		const video = document.createElement('video');
		video.src = element.dataset.path;
		video.controls = true;
		video.setAttribute('playsinline', '');
		video.setAttribute('type', 'video/mp4');
		videoWrapper.classList.add('video2__wrapper--hidden');
		videoPlayer.appendChild(video);
		video.play();

		videoPlay.addEventListener('click', () => {
			videoWrapper.classList.add('video2__wrapper--hidden');
			video.play();
		});

		// video.addEventListener('play', () => videoWrapper.classList.add('video2__wrapper--hidden'));

		// video.addEventListener('stop', () => videoWrapper.classList.remove('video2__wrapper--hidden'));
	}
};

export const initVideo = (element) => element.addEventListener('click', () => createVideo(element));
