import { classInstance } from '@/shared/helpers/helpers';

export class SuccessResult {
	constructor(selector, options = {}) {
		this.$container = selector;
		this.active = false;

		if (!this.$container) return;

		this.selectorTitle = options.selectorTitle || '.js-success-result__title';
		this.selectorText = options.selectorText || '.js-success-result__text';

		this.init();
	}

	init() {
		classInstance.set(this.$container, { successResult: this });
		this.$title = this.$container.querySelector(this.selectorTitle);
		this.$text = this.$container.querySelector(this.selectorText);
	}

	destroy() {
		classInstance.del(this.$container, 'successResult');
	}

	update() {
		this.destroy();
		this.init();
	}

	open(data) {
		this.$title.innerHTML = data.title || '';
		this.$text.innerHTML = data.text || '';
		app.modal.open('#success-result');
	}
}
