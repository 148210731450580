import { classInstance } from '@/shared/helpers/helpers';
import { Form } from '@/shared/form/form';

export class OrderBuyClickOne {
	constructor(selector) {
		this.$container = selector;

		if (!this.$container) return;

		this.init();
	}

	init() {
		classInstance.set(this.$container, { orderCall: this });
		this.$submit = this.$container.querySelector('button[type="submit"]');

		this.instance = new Form(this.$container);
		this.$container.addEventListener('submit', this.submitHandler.bind(this));
	}

	destroy() {
		classInstance.del(this.$container, 'orderCall');
		this.$container.removeEventListener('submit', this.submitHandler);
	}

	reinit() {
		this.destroy();
		this.init();
	}

	showLoading() {
		this.$submit.classList.add('loading');
	}

	hideLoading() {
		this.$submit.classList.remove('loading');
	}

	submitHandler(event) {
		event.preventDefault();

		if (!this.instance.formCheck(false)) return;

		window.BX.onCustomEvent('o2k.onSubmitOneClickProduct', {
			component: this,
			formData: new FormData(this.$container),
		});
	}
}
