import { Header } from '@/widgets/header/header';
import { HeaderConfirm } from '@/widgets/header/header-confirm/header-confirm.js';

import { initVideo } from '@/shared/common/video2/video2';
import { SwiperThumbs } from '@/entities/swiper-thumbs/swiper-thumbs';
import { SwiperBanner } from '@/entities/swiper-banner/swiper-banner';

window.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.js-header').forEach((element) => new Header(element));

	document.querySelectorAll('.js-video2').forEach((element) => initVideo(element));
	document.querySelectorAll('.js-swiper-banner').forEach((slider) => new SwiperBanner(slider));
	document.querySelectorAll('.js-swiper-thumbs').forEach((slider) => new SwiperThumbs(slider));
	document.querySelectorAll('.js-header-confirm').forEach((element) => {
		new HeaderConfirm(element);
		element.addEventListener('reject', () => {
			app.modal.open('#location');
		});
	});
});
