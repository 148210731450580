import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export class CatalogCard {
	constructor(selector) {
		this.$container = selector;
		if (!this.$container) return;
		this.init();
	}

	init() {
		setTimeout(() => {
			this.$container.querySelector('.js-catalog-card-pagination').style.transition = '';
		}, 300);

		// slider
		this.$slider = this.$container.querySelector('.js-catalog-card-slider');
		this.$slides = this.$container.querySelectorAll('.js-catalog-card-slide');
		this.$swiper = null;

		if (this.$slides.length > 1) {
			new Swiper(this.$slider, {
				modules: [Pagination],
				slidesPerView: 1,
				spaceBetween: 0,
				pagination: {
					el: this.$container.querySelector('.js-catalog-card-pagination'),
				},
				on: {
					paginationRender(swiper) {
						swiper.pagination.bullets.forEach((bullet, idx) => {
							bullet.addEventListener('mouseover', function () {
								swiper.slideTo(idx, 500, false);
							});
						});
					},
				},
			});
		}

		// buttons

		this.$favorites = this.$container.querySelector('.js-catalog-card-favorites');
		this.$compare = this.$container.querySelector('.js-catalog-card-compare');
		this.$buy = this.$container.querySelector('.js-catalog-card-buy');
		this.$modalAddedToCart = document.querySelector('.js-modal-added-to-cart');

		this.$favorites && this.$favorites.addEventListener('click', this.favoritesHandler.bind(this));
		this.$compare && this.$compare.addEventListener('click', this.compareHandler.bind(this));
		this.$buy && this.$buy.addEventListener('click', this.addToCartHandler.bind(this));

		// transform by hover
		this.$info = this.$container.querySelector('.js-catalog-card-info');
		this.$previews = this.$container.querySelector('.js-catalog-card-previews');
		this.$colors = this.$container.querySelector('.catalog__card-colors');

		this.$info && this.$container.addEventListener('mouseenter', this.transformDownHandler.bind(this));
		this.$info && this.$container.addEventListener('mouseleave', this.transformUpHandler.bind(this));

		this.$timeOut = null;
	}

	favoritesHandler() {
		this.$container.classList.toggle('js-added-to-favorites');
	}

	compareHandler() {
		this.$container.classList.toggle('js-added-to-compare');
	}

	addToCartHandler() {
		this.$container.classList.toggle('js-added-to-cart');

		if (this.$container.classList.contains('js-added-to-cart')) {
			if (this.$modalAddedToCart) {
				this.$modalAddedToCart.classList.add('active');
				document.body.classList.add('lock');
			}
		}
	}

	transformDownHandler() {
		if (this.$previews && this.$previews.offsetHeight) {
			this.$info.style.transform = `translateY(${this.$previews.offsetHeight - this.$colors.offsetHeight}px)`;
			this.$info.style.zIndex = '3';
		}

		if (this.$timeOut) clearTimeout(this.$timeOut);
	}

	transformUpHandler() {
		this.$info.style.transform = 'translateY(0)';
		this.$timeOut = setTimeout(() => {
			this.$info.style.zIndex = '';
		}, 300);
	}
}
