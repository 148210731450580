export function useGetValid(name: string): ResultVE {
	const result: ResultVE = {
		valid: false,
		error: false,
		message: '',
	};

	const $checked = document.querySelector(`input[name="${name}"]:checked`);

	if ($checked) {
		result.valid = true;
		result.error = false;
	} else {
		result.valid = false;
		result.error = true;
	}

	return result;
}
