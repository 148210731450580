export class ModalCartPreview {
	constructor(selector) {
		this.$container = selector;
		if (!this.$container) return;
		this.init();
	}

	init() {
		this.$container.style.transition = '';

		this.$closeButtons = this.$container.querySelectorAll('.js-cart-preview-close');
		this.$showCartPreviewBtns = document.querySelectorAll('.js-show-cart-preview-btn');

		this.$closeButtons.length > 0 &&
			this.$closeButtons.forEach((btn) => btn.addEventListener('click', this.closeModalHandler.bind(this)));

		this.$showCartPreviewBtns.length > 0 &&
			this.$showCartPreviewBtns.forEach((btn) => btn.addEventListener('click', this.showCartPreview.bind(this)));

		const mm640 = window.matchMedia('(min-width: 640px)');
		mm640.addEventListener('change', this.resizeHandler);
	}

	closeModalHandler() {
		this.$container.classList.remove('active');
		document.body.classList.remove('lock');
		window.removeEventListener('click', this.closeByWindowHandler);
	}

	closeByWindowHandler = (e) => {
		const isModal = e.target.closest('.js-modal-cart-preview');
		if (!isModal) {
			this.$container.classList.remove('active');
			document.body.classList.remove('lock');
			window.removeEventListener('click', this.closeByWindowHandler);
		}
	};

	showCartPreview(e) {
		const isModalActive = this.$container.classList.contains('active');
		e.preventDefault();
		e.stopPropagation();
		if (!isModalActive) {
			this.$container.classList.add('active');
			window.addEventListener('click', this.closeByWindowHandler);
			window.innerWidth < 640 && document.body.classList.add('lock');
		} else {
			this.$container.classList.remove('active');
			document.body.classList.remove('lock');
			window.removeEventListener('click', this.closeByWindowHandler);
		}
	}

	resizeHandler = (e) => {
		if (e.matches) {
			this.$container.classList.contains('active') && document.body.classList.remove('lock');
		} else {
			this.$container.classList.contains('active') && document.body.classList.add('lock');
		}
	};
}
