import { classInstance } from '@/shared/helpers/helpers';

import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export class SwiperBanner {
	constructor(selector, options = {}) {
		this.$container = selector;

		if (!this.$container) return;

		this.selectorNext = options.selectorNext || '.js-swiper-next';
		this.selectorPrev = options.selectorPrev || '.js-swiper-prev';
		this.selectorPagination = options.selectorPagination || '.js-swiper-pagination';

		this.init();
	}

	init() {
		classInstance.set(this.$container, { swiperBanner: this });

		this.swiper = new Swiper(this.$container.querySelector('.swiper'), {
			modules: [Navigation, Pagination, EffectFade, Autoplay],
			slidesPerView: 1,
			spaceBetween: 0,
			rewind: true,
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			pagination: {
				el: this.$container.querySelector(this.selectorPagination),
				clickable: true,
			},
			navigation: {
				prevEl: this.$container.querySelector(this.selectorPrev),
				nextEl: this.$container.querySelector(this.selectorNext),
			},
		});
	}

	destroy() {
		classInstance.del(this.$container, 'swiperBanner');
		this.swiper.destroy(true, true);
	}

	update() {
		this.destroy();
		this.init();
	}
}
